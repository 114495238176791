import _ from 'lodash'

export const isSafe = v => v !== undefined && v !== null

export const safeObject = (instance) => {
  const resolve = {}
  _.each(instance, (value, key) => {
    if (value !== undefined && value !== null) resolve[key] = value
  })

  return resolve
}
