import FactoryModel from './factoryModel'
import { ResponsiveModel } from '../responsiveModel'
import { ThemeModel } from '../themeModel'
import { NotificationModel } from '../notificationModel'
import { AppModel } from '../appModel'
import { DialogModel } from '../dialogModel'
import { PhoneModel } from '../phoneModel'

export const factoryModelInit = () => {
  FactoryModel.init({
    ResponsiveModel: new ResponsiveModel(),
    ThemeModel: new ThemeModel(),
    NotificationModel: new NotificationModel(),
    AppModel: new AppModel(),
    DialogModel: new DialogModel(),
    PhoneModel: new PhoneModel()
  })
}
