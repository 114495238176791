import React from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import withMargin from 'sdk/ui/common/withMargin'
import { applyClassName } from 'sdk/utils/style'

const Root = styled.div`
  text-align: ${props => props.align};
  transition: color .3s ease-in-out;
  margin-bottom: 6px;
  font-family: ${props => props.theme.fontFamily};
  line-height: 1.5;
  cursor: ${props => (props.onClick ? 'pointer' : 'inherit')};
  
  & * {
    font-family: ${props => props.theme.fontFamily};
    line-height: 1.5;
  }

  & del {
    background: inherit;
  }
  
  & code {
    color: inherit;
  }
  
  & a, a:hover, a:visited, a:focus {
    color: inherit;
    text-decoration: inherit;
    cursor: pointer;
  }
`

const Title = ({
  className, style, onClick,

  level, type,
  isUnderline, align,
  children,
  theme
}) => (
    <Root
        className={applyClassName('title-container', className)}
        align={align}
        onClick={onClick}
        level={level}
        style={{
          color: theme.colors[type] ? theme.colors[type] : type !== 'text' ? type : 'inherit',
          fontWeight: level !== 4 || level === 3 ? 600 : 500,
          fontSize: (() => {
            switch (level) {
              case 1: return '38px'
              case 2: return '30px'
              case 3: return '24px'
              case 4: return '20px'
              default: return '38px'
            }
          })(),
          lineHeight: (() => {
            switch (level) {
              case 1: return 1.23
              case 2: return 1.35
              case 3: return 1.35
              case 4: return 1.4
              default: return '38px'
            }
          })(),
          cursor: onClick ? 'pointer' : 'inherit',
          textDecoration: isUnderline ? 'underline' : 'inherit',
          ...style
        }}
    >
        {children}
    </Root>
)

Title.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,

  onClick: PropTypes.func,

  level: PropTypes.oneOf([1, 2, 3, 4]),
  type: PropTypes.string, // colors of theme or custom color
  isUnderline: PropTypes.bool,
  align: PropTypes.oneOf(['left', 'center', 'right'])
}

Title.defaultProps = {
  level: 1,
  type: 'text',
  isUnderline: false,
  align: 'left'
}

export default withMargin({ displayName: 'Title' })(withTheme(Title))
