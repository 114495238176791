import { observable, action } from 'mobx'
import _ from 'lodash'
import uuid from 'uuid/v4'
import deepMerge from 'deepmerge'
import BaseModel from '../baseModel'

class NotificationModel extends BaseModel {
  @observable messages = []

  animationDuration = 5000

  notifyError = action(({ message, type, forceMessage }) => {
    const resolveMessage = _.get(message, 'response.data.error.title') || forceMessage || message

    const id = uuid()
    const messages = deepMerge([], this.messages)
    messages.push({ type, message: resolveMessage, id })

    this.messages = messages

    setTimeout(() => {
      if (!_.isEmpty(this.messages)) {
        this.action(() => {
          const removeMessages = deepMerge([], this.messages)
          const index = _.findIndex(removeMessages, r => r.id === id)
          removeMessages.splice(index, 1)
          this.messages = removeMessages
        })
      }
    }, this.animationDuration)
  })

  remove = action(id => {
    if (!_.isEmpty(this.messages)) {
      const removeMessages = deepMerge([], this.messages)
      const index = _.findIndex(removeMessages, r => r.id === id)
      removeMessages.splice(index, 1)
      this.messages = removeMessages
    }
  })
}

export { NotificationModel }
