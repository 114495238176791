import _ from 'lodash'
import { hexToRgba, isDark, darken, lighten } from 'sdk/utils/colors'

export const fontSize = {
  base: 14
}

export const fontSizeKit = {
  base: '14px'
}

export const fontFamily = "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'"

export const colors = {
  background: '#f4f5f6',
  text: '#0d152b',

  primary: '#49b82b',
  success: '#49b82b',
  danger: '#fd573e',
  warning: '#e3ae1a',

  gray1: '#e0e0e0',
  gray2: '#bdbdbd',
  gray3: '#9e9e9e',
  gray4: '#757575',
  gray5: '#616161',

  overflowBackground: 'rgba(0, 0, 0, 0.5)'
}

export const breakPoints = {
  mobile: 768,
  tablet: 1200,
  desktop: 1400,
  largeDesktop: 1600
}

export const viewPorts = {
  mobile: `@media screen and (max-width: ${breakPoints.mobile - 1}px)`,
  tablet: `@media screen and (min-width: ${breakPoints.mobile}px) and (max-width: ${breakPoints.tablet - 1}px)`,
  touch: `@media screen and (max-width: ${breakPoints.tablet - 1}px)`,
  beforeDesktop: `@media screen and (max-width: ${breakPoints.desktop - 1}px)`,
  desktop: `@media screen and (min-width: ${breakPoints.desktop}px)`,
  largeDesktop: `@media screen and (min-width: ${breakPoints.largeDesktop}px)`
}

export const theme = {
  colors,
  breakPoints,
  viewPorts,
  fontSize,
  fontSizeKit,
  fontFamily
}

export const applyTheme = (cT = null, newTheme = {}) => {
  const currentTheme = cT || theme
  const newColors = _.get(newTheme, 'colors')
  const newFontSize = _.get(newTheme, 'fontSize')
  const newFontFamily = _.get(newTheme, 'fontFamily')

  const background = _.get(newColors, 'background') || _.get(currentTheme, 'colors.background')
  const isDarkTheme = isDark(background)
  const text = _.get(newColors, 'text') || _.get(currentTheme, 'colors.text')
  const primary = _.get(newColors, 'primary') || _.get(currentTheme, 'colors.primary')
  const success = _.get(newColors, 'success') || _.get(currentTheme, 'colors.success')
  const danger = _.get(newColors, 'danger') || _.get(currentTheme, 'colors.danger')
  const warning = _.get(newColors, 'warning') || _.get(currentTheme, 'colors.warning')

  const gray1 = _.get(newColors, 'gray1') || _.get(currentTheme, 'colors.gray1')
  const gray2 = _.get(newColors, 'gray2') || _.get(currentTheme, 'colors.gray2')
  const gray3 = _.get(newColors, 'gray3') || _.get(currentTheme, 'colors.gray3')
  const gray4 = _.get(newColors, 'gray4') || _.get(currentTheme, 'colors.gray4')
  const gray5 = _.get(newColors, 'gray5') || _.get(currentTheme, 'colors.gray5')

  const overflowBackground = _.get(newColors, 'overflowBackground') || _.get(currentTheme, 'colors.overflowBackground')

  return ({
    ...currentTheme,
    colors: {
      background,
      text,
      disable: hexToRgba(!isDarkTheme ? text : background, 0.65),
      gray1,
      gray2,
      gray3,
      gray4,
      gray5,
      overflowBackground,
      primary,
      primaryLight: lighten(primary, 0.1),
      primaryDark: darken(primary, 0.2),
      success,
      danger,
      warning,
      isDarkTheme
    },
    fontSize: newFontSize ? { base: newFontSize } : currentTheme.fontSize,
    fontSizeKit: newFontSize ? { base: `${newFontSize}px` } : currentTheme.fontSizeKit,
    fontFamily: newFontFamily || currentTheme.fontFamily
  })
}
