import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import withMargin from 'sdk/ui/common/withMargin'
import withFormProps from 'sdk/ui/common/withFormProps'
import { applyClassName } from 'sdk/utils/style'
import MaskInput from './MaskInput'
import InputComponent from './InputComponent'

const Input = ({
  className, style,

  type, min, max,
  value, placeholder,
  isDisable,
  tabIndex,
  step,
  mask, unmask, maskChar,
  normalizer, denormalizer, prefix, postfix, error, isShowForceValidate = false,

  onFocus, onMetaFocus, onBlur, onMetaBlur, onChange, onKeyDown, onKeyUp, onKeyPress,
  onUp, onDown, onClick, textAlign,

  onChangeIsInput, onChangeIsFocus
}) => {
  const [currentValue, setValue] = useState(value)
  const [isInput, setIsInput] = useState(false)
  const [isFocus, setIsFocus] = useState(false)

  const handleChange = (isChangeOutside = true) => e => {
    const c = e && e.target ? e.target.value : e
    let v = denormalizer ? denormalizer(c.toString()) : c
    v = type === 'number' ? _.isNaN(Number(v)) ? min : v > max ? max : v : v

    const maskRegexp = new RegExp(`${maskChar}*`, 'ig')
    const outsideValue = mask ? unmask ? unmask(v).replace(maskRegexp, '') : v.replace(maskRegexp, '') : v
    if (normalizer) {
      v = normalizer(mask ? unmask ? unmask(v).replace(maskRegexp, '') : v.replace(maskRegexp, '') : v)
    }

    if (isChangeOutside) {
      setIsInput(true)
      if (onChangeIsInput) onChangeIsInput(true)

      if (onChange) onChange(outsideValue)
    }

    setValue(v)
  }

  const handleFocus = () => {
    setIsFocus(true)
    if (onChangeIsFocus) onChangeIsFocus(true)
    if (onFocus) onFocus()
    if (onMetaFocus) onMetaFocus()
  }

  const handleBlur = () => {
    setIsFocus(false)
    if (type === 'number' && Number(currentValue) < min) {
      setValue(min)
      if (onChange) onChange(min)
    }

    if (onChangeIsInput) onChangeIsInput(true)
    setIsInput(true)
    if (onChangeIsFocus) onChangeIsFocus(false)
    if (onBlur) onBlur()
    if (onMetaBlur) onMetaBlur()
  }

  useEffect(() => {
    if (!isFocus) handleChange(false)(value)
  }, [value])

  const handleKeyDown = e => {
    switch (e.keyCode) {
      case 40:
        if (type === 'number') handleChange()(_.isNumber(Number(value)) ? Number(value) - step < min ? min : Number(value) - step : min)
        if (onDown) onDown()
        break
      case 38:
        if (type === 'number') handleChange()(_.isNumber(Number(value)) ? Number(value) + step > max ? max : Number(value) + step : max)
        if (onUp) onUp()
        break
    }
    if (onKeyDown) onKeyDown(e)
  }

  const handleKeyUp = e => {
    if (onKeyUp) onKeyUp(e)
  }

  const inputProps = {
    className: applyClassName('input-container', className),
    style: { width: '100%', ...style },
    value: currentValue,
    isDisable,
    placeholder,
    onFocus: handleFocus,
    onBlur: handleBlur,
    onChange: handleChange(),
    onKeyDown: handleKeyDown,
    onKeyUp: handleKeyUp,
    onKeyPress,
    onClick,
    min,
    max,
    tabIndex,
    isFocus,
    prefix,
    postfix,
    error,
    isInput: isInput || isShowForceValidate,
    textAlign
  }

  return (
    !mask ? <InputComponent {...inputProps} /> :
      <MaskInput
          mask={mask}
          maskChar={maskChar}
          placeholder={placeholder}
          Input={InputComponent}
          {...inputProps}
      />
  )
}

Input.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,

  value: PropTypes.any,
  min: PropTypes.number,
  max: PropTypes.number,
  placeholder: PropTypes.string,
  isDisable: PropTypes.bool,
  step: PropTypes.number,
  mask: PropTypes.string,
  maskChar: PropTypes.string,
  unmask: PropTypes.func,
  prefix: PropTypes.any,
  postfix: PropTypes.any,

  onFocus: PropTypes.func,
  onMetaFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onMetaBlur: PropTypes.func,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  onKeyPress: PropTypes.func,
  onClick: PropTypes.func,
  onUp: PropTypes.func,
  onDown: PropTypes.func,
  normalizer: PropTypes.func,
  denormalizer: PropTypes.func,
  textAlign: PropTypes.oneOf(['left', 'center', 'right']),

  tabIndex: PropTypes.any,
  // withFormProps
  onChangeIsInput: PropTypes.func,
  onChangeIsFocus: PropTypes.func
}

Input.defaultProps = {
  value: '',
  min: 0,
  max: 10000000,
  placeholder: '',
  isDisable: false,
  step: 1,
  mask: '',
  maskChar: '_',
  unmask: null,
  tabIndex: null,
  textAlign: 'left'
}

export default withFormProps({ displayName: 'Input' })(withMargin({ displayName: 'Input' })(Input))
