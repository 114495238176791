import { observable, action, computed } from 'mobx'
import { breakPoints, theme } from 'sdk/styles'
import BaseModel from '../baseModel'

class ResponsiveModel extends BaseModel {
  @observable width = window.innerWidth

  @action setWidth = width => {
    this.width = width
  }

  @computed get isPhone() {
    return this.width < breakPoints.mobile
  }

  @computed get isTablet() {
    return this.width >= breakPoints.phone && this.width < breakPoints.tablet
  }

  @computed get isTabletAndPhone() {
    return this.width < breakPoints.tablet
  }

  @computed get isBeforeDesktop() {
    return this.width < breakPoints.desktop
  }

  @computed get isDesktop() {
    return this.width >= breakPoints.tablet
  }

  @computed get isMiddleDesktop() {
    return this.width >= breakPoints.desktop &&
        this.width < breakPoints.largeDesktop
  }

  @computed get isLargeDesktop() {
    return this.width >= breakPoints.largeDesktop
  }

  @computed get isNotPhone() {
    return this.width >= breakPoints.mobile
  }
}

export { ResponsiveModel }
