import Check from './Check'
import Loading from './Loading'
import Close from './Close'
import Warning from './Warning'

export default {
  check: Check,
  loading: Loading,
  close: Close,
  warning: Warning
}
