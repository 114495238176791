import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import withMargin from 'sdk/ui/common/withMargin'
import styled, { withTheme } from 'styled-components'
import { applyClassName } from 'sdk/utils/style'

const Root = styled(props => {
  const resolveProps = { ...props }
  _.unset(resolveProps, 'isClick')
  _.unset(resolveProps, 'cursor')
  _.unset(resolveProps, 'isFlex')
  _.unset(resolveProps, 'isInline')
  return <div {...resolveProps}/>
})`
  position: relative;
  cursor: ${props => (!props.cursor ? props.isClick ? 'pointer' : 'inherit' : props.cursor)};
  display: ${props => (props.isFlex ? !props.isInline ? 'flex' : 'inline-flex' : !props.isInline ? 'block' : 'inline-block')};
  line-height: 1.5;
  font-weight: 400;
  color: ${props => props.theme.colors.text};
  font-family: ${props => props.theme.fontFamily};
  
  & * {
    font-family: ${props => props.theme.fontFamily};
    line-height: 1.5;
  }
`

const Block = ({
  style, className,
  horizontalPlacement, verticalPlacement, isCenter, isRem,
  padding, paddingLeft, paddingRight, paddingTop, paddingBottom,
  cursor, color, background, borderWidth, borderColor, borderRadius, isInline,

  onClick, onMouseOver, onMouseLeave,

  children, theme
}) => {
  const isFlex = horizontalPlacement !== 'none' || verticalPlacement !== 'none' || isCenter

  const applyPlacement = placement => {
    switch (placement) {
      case 'none':
        return 'flex-start'
      case 'left':
        return 'flex-start'
      case 'right':
        return 'flex-end'
      case 'center':
        return 'center'
      case 'top':
        return 'flex-start'
      case 'bottom':
        return 'flex-end'
      case 'between':
        return 'space-between'
      default:
        return 'initial'
    }
  }

  return (
    <Root
      className={applyClassName('block', className)}
      onClick={onClick}
      isClick={!!onClick}
      cursor={cursor}
      isFlex={isFlex}
      isInline={isInline}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      style={{
        ...style,
        ..._.omitBy({
          justifyContent: isFlex ? !isCenter ? applyPlacement(horizontalPlacement) : 'center' : null,
          alignItems: isFlex ? !isCenter ? applyPlacement(verticalPlacement) : 'center' : null,
          padding: padding ? `${isRem ? padding * 14 : padding}px` : null,
          paddingLeft: paddingLeft && !padding ? `${isRem ? paddingLeft * 14 : paddingLeft}px` : null,
          paddingRight: paddingRight && !padding ? `${isRem ? paddingRight * 14 : paddingRight}px` : null,
          paddingTop: paddingTop && !padding ? `${isRem ? paddingTop * 14 : paddingTop}px` : null,
          paddingBottom: paddingBottom && !padding ? `${isRem ? paddingBottom * 14 : paddingBottom}px` : null,
          color: color ? theme.colors[color] ? theme.colors[color] : color : null,
          background: background ? theme.colors[background] ? theme.colors[background] : background : null,
          border: borderWidth ? `${isRem ? borderWidth * 14 : borderWidth}px solid ${borderColor ? theme.colors[borderColor] || borderColor : theme.colors.text}` : null,
          borderRadius: borderRadius ? _.isNumber(borderRadius) ? `${isRem ? borderRadius * 14 : borderRadius}px` : borderRadius : null
        }, _.isNull)
      }}
    >
      {children}
    </Root>
  )
}

Block.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,

  horizontalPlacement: PropTypes.oneOf(['left', 'center', 'right', 'between', 'none']),
  verticalPlacement: PropTypes.oneOf(['top', 'center', 'bottom', 'none']),
  isCenter: PropTypes.bool,
  padding: PropTypes.number,
  paddingLeft: PropTypes.number,
  paddingRight: PropTypes.number,
  paddingTop: PropTypes.number,
  paddingBottom: PropTypes.number,
  color: PropTypes.string, // colors of theme or custom color/background
  background: PropTypes.string, // colors of theme or custom color/background
  borderWidth: PropTypes.number,
  borderColor: PropTypes.string, // colors of theme or custom color/background
  borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isRem: PropTypes.bool,
  cursor: PropTypes.string,
  isInline: PropTypes.bool,

  onClick: PropTypes.func,
  onMouseOver: PropTypes.func,
  onMouseLeave: PropTypes.func
}

Block.defaultProps = {
  horizontalPlacement: 'none',
  verticalPlacement: 'none',
  padding: 0,
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 0,
  paddingBottom: 0,
  isCenter: false,
  color: '',
  background: '',
  borderWidth: 0,
  borderColor: '',
  borderRadius: 0,
  isRem: true,
  cursor: '',
  isInline: false
}

export default withTheme(withMargin({ displayName: 'Block' })(Block))
