import { observable, action, computed } from 'mobx'
import _ from 'lodash'
import FactoryModel from 'sdk/models/factoryModel'
import FactoryProvider from 'sdk/providers/factoryProvider'
import { openFileLink } from 'sdk/utils/link'
import BaseModel from '../baseModel'

class DialogModel extends BaseModel {
  @observable lastName = ''
  @observable firstName = ''
  @observable patronymic = ''

  @observable clientComment = ''

  @observable isOpen = false
  @observable isShowDetails = false

  @observable dialog = {
    title: 'Заявка на кредит',
    subTitle: 'Для продолжения заполните контактные данные:',
    successText: 'Заявка передана в Контакт-Центр! Ожидайте звонка оператора.',
    additionalSuccessText: 'Номер телефона, с которого поступит звонок: +7 (499) 444-19-04'
  }

  @observable closeRedirect = ''
  onClose = null

  @observable isSendToPosline = false
  @observable poslineRedirectUrl = ''

  @observable isSend = false
  @observable isLoading = false

  @computed get isFillName() {
    return this.lastName && this.firstName
  }

  @computed get isSendRequest() {
    return !!FactoryModel.PhoneModel.isConfirmCode && !!this.lastName && !!this.firstName && !!this.phone
  }

  clear = action(() => {
    this.lastName = ''
    this.firstName = ''
    this.patronymic = ''
    this.clientComment = ''
    this.isSendToPosline = false
    this.poslineRedirectUrl = ''
    this.isLoading = false
  })

  apply = action(data => {
    if (data?.firstName) this.firstName = data?.firstName
    if (data?.lastName) this.lastName = data?.lastName
    if (data?.patronymic) this.patronymic = data?.patronymic
    if (data?.clientComment) this.clientComment = data?.clientComment
    if (data?.closeRedirect) this.closeRedirect = data?.closeRedirect
    if (data?.onClose) this.onClose = data?.onClose

    if (data?.dialog && !_.isEmpty(data?.dialog)) {
      _.each(data?.dialog, (value, key) => {
        this.dialog[key] = value
      })
    }
  })

  open = action(isOpen => {
    this.isOpen = isOpen
  })

  showDetails = action(isOpen => {
    this.isShowDetails = isOpen
  })

  send = async () => {
    try {
      this.applyData({ isLoading: true })
      if (!this.isSendToPosline) {
        await FactoryProvider.TelephonistProvider.sendRequest(
          this.lastName, this.firstName, this.patronymic, this.clientComment, FactoryModel?.PhoneModel?.confirmKey
        )
      } else {
        const { orderURL } = await FactoryProvider.PoslineProvider.sendRequest(
          this.lastName, this.firstName, this.patronymic,
            FactoryModel?.PhoneModel?.phone, FactoryModel?.PhoneModel?.confirmKey
        )
        this.applyData({ poslineRedirectUrl: orderURL })
      }
      this.applyData({
        lastName: '',
        firstName: '',
        patronymic: '',
        clientComment: '',
        isSend: true
      })
      FactoryModel.PhoneModel.clear()
    } catch (e) {
      FactoryModel.NotificationModel.notifyError({ message: e, forceMessage: 'Не удается отправить заявку.' })
    } finally {
      this.applyData({ isLoading: false })
    }
  }

  finish = action(() => {
    this.isSend = false
    this.isOpen = false

    if (this.onClose) this.onClose()
    if (!this.isSendToPosline && this.closeRedirect) {
      openFileLink(this.closeRedirect, false)
    }

    FactoryModel.PhoneModel.clear()
    this.clear()
  })
}

export { DialogModel }
