import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Icon from 'sdk/ui/Icon'
import { hexToRgba } from 'sdk/utils/colors'
import withMargin from 'sdk/ui/common/withMargin'
import { applyClassName } from 'sdk/utils/style'

const Root = styled(props => {
  const resolveProps = { ...props }
  _.unset(resolveProps, 'type')
  _.unset(resolveProps, 'isDisable')
  _.unset(resolveProps, 'isLoading')
  return <button {...resolveProps}/>
})`
  -webkit-appearance: button;
  height: 40px;
  padding: 0 15px;
  font-size: 16px;
  border-radius: 8px;
  outline: 0;
  line-height: 1.5;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  cursor: ${props => (props.isDisable || props.isLoading ? 'not-allowed' : 'pointer')};
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;
  color: ${props => {
    if (props.isDisable) return props.theme.colors.disable

    if (props.type === 'default') return props.theme.colors.text
    return '#fff'
  }};
  background-color: ${props => {
    if (props.isDisable) return props.theme.colors.gray1

    switch (props.type) {
      case 'primary': return props.theme.colors.primary
      default: return '#fff'
    }
  }};
  border-color: ${props => {
    if (props.isDisable) return props.theme.colors.gray1

    switch (props.type) {
      case 'primary': return props.theme.colors.primary
      default: return props.theme.colors.gray1
    }
  }};
  box-shadow: ${props => {
    if (props.isDisable) return 'none'

    switch (props.type) {
      case 'primary': return `0 2px 0 ${hexToRgba(props.theme.colors.text, 0.045)}`
      default: return `0 2px 0 ${hexToRgba(props.theme.colors.text, 0.015)}`
    }
  }};
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: ${props => props.theme.colors.text};
  font-family: ${props => props.theme.fontFamily};
  
  & * {
    font-family: ${props => props.theme.fontFamily};
  }
  
  &::selection {
    color: ${props => props.theme.colors.background};
    background: ${props => props.theme.colors.primary};
  }
  
  &:hover, &:focus {
    background: ${props => {
    if (props.isDisable) return props.theme.colors.gray1

    switch (props.type) {
      case 'primary': return props.theme.colors.primaryDark
      default: return '#fff'
    }
  }};
    color: ${props => {
    if (props.isDisable) return props.theme.colors.disable

    switch (props.type) {
      case 'primary': return '#fff'
      default: return props.theme.colors.primaryDark
    }
  }};
    border-color: ${props => (props.isDisable ? props.theme.colors.gray1 : props.theme.colors.primaryDark)};
  }
  
  &:active {
    color: ${props => {
    if (props.isDisable) return props.theme.colors.disable

    switch (props.type) {
      case 'primary': return '#fff'
      default: return props.theme.colors.primaryDark
    }
  }};
    background-color: ${props => {
    if (props.isDisable) return props.theme.colors.gray1

    switch (props.type) {
      case 'primary': return props.theme.colors.primaryDark
      default: return '#fff'
    }
  }};
    border-color: ${props => (props.isDisable ? props.theme.colors.gray1 : props.theme.colors.primaryDark)};
  }
  
  & > span {
    transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    pointer-events: none;
  }
`

const Container = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
`

const Button = ({
  className, style,

  type, isDisable, isLoading,

  onClick, children
}) => (
    <Root
        className={applyClassName('button', className)}
        style={style}
        type={type}
        isDisable={isDisable}
        isLoading={isLoading}
        onClick={!isDisable && !isLoading ? onClick : null}
    >
      <Container className={applyClassName('button-container')}>
        <span>{children}</span>
        {isLoading &&
        <Icon
          className={applyClassName('button-loading-icon')}
          icon="loading"
          color={isDisable || type !== 'primary' ? 'disable' : 'white'}
          style={{ marginLeft: '10px', marginTop: '5px' }}
        />
        }
      </Container>
    </Root>
)

Button.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,

  type: PropTypes.oneOf(['default', 'primary']),
  isDisable: PropTypes.bool,
  isLoading: PropTypes.bool,

  onClick: PropTypes.func
}

Button.defaultProps = {
  type: 'default',
  isDisable: false,
  isLoading: false
}

export default withMargin({ displayName: 'Button' })(Button)
