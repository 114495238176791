import _ from 'lodash'
import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import Block from 'sdk/ui/Block'
import styled, { keyframes, withTheme } from 'styled-components'
import { Title } from 'sdk/ui/Typography'
import { mountHook } from 'sdk/hooks/mount'
import { applyClassName } from 'sdk/utils/style'

const animationInOverflow = keyframes`
  from {
    opacity: 0;
  }
  
  to {
    opacity: 1;
  }
`

const Overflow = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  background-color: ${props => (props.theme.isNotPhone ? props.theme.colors.overflowBackground : props.theme.colors.background)};
  outline: 0;
  animation: ${animationInOverflow} .175s ease-in;
  z-index: 1000000;
`

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  display: ${props => (props.theme.isNotPhone ? 'flex' : 'block')};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1000000;
  font-family: ${props => props.theme.fontFamily};
  
  & * {
    font-family: ${props => props.theme.fontFamily};
  }
`

const animationInRoot = keyframes`
  from {
    opacity: 0;
    transform: scale(0.4);
  }
  
  to {
    opacity: 1;
    transform: scale(1);
  }
`

const Root = styled.div`
  position: ${props => (props.theme.isNotPhone ? 'absolute' : 'relative')};
  top: ${props => (props.theme.isNotPhone ? '5%' : 0)};
  min-height: 10vh;
  margin-bottom: ${props => (props.theme.isNotPhone ? '3%' : 0)};
  background: ${props => props.theme.colors.background};
  border-radius: 16px;
  padding: ${props => (props.theme.isNotPhone ? '20px 40px' : '16px')};
  margin: ${props => (!props.theme.isNotPhone ? '16px' : 0)};
  animation: ${animationInRoot} .175s ease-in;
  box-shadow: ${props => (!props.theme.isNotPhone ? '0 4px 12px rgba(13, 21, 43, 0.15)' : 'none')};
`

const CloseIcon = styled(Block)`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  top: 14px;
  right: 14px;
  background: #fdded9;
  color: #fd9a8b;
  border-radius: 8px;
`

const Modal = ({
  className, style,

  title, isOpen,
  footer, width,
  onClose, isClosable, isHide,
  children, theme
}) => {
  const [isClickInRoot, setIsClickInRoot] = useState(false)
  const handleEsc = e => {
    if (e.keyCode === 27 && onClose && isHide) {
      onClose()
    }
  }

  let handleKeyDown = null
  mountHook(() => {
    handleKeyDown = document.addEventListener('keydown', e => {
      handleEsc(e)
    })
  }, () => {
    if (handleKeyDown) {
      document.removeEventListener('keydown', handleKeyDown)
    }
  })

  return (
    isOpen ?
          <Fragment>
              <Overflow className={applyClassName('modal-overflow')}/>
              <Container
                className={applyClassName('modal-container')}
                onClick={() => {
                  if (onClose && !isClickInRoot && isHide) onClose()
                }}
              >
                  <Root
                      className={applyClassName('modal-content', className)}
                      style={{
                        ...style,
                        width: theme.width >= theme.breakPoints.mobile ? (width || '50%') : 'auto'
                      }}
                      width={width}
                      onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                      }}
                      onMouseDown={() => setIsClickInRoot(true)}
                      onMouseUp={() => setIsClickInRoot(false)}
                  >
                      {title &&
                      <Block
                        width="100%"
                        marginBottom={2}
                        className={applyClassName('modal-title')}
                      >
                          {_.isString(title) ?
                              <Block isCenter width="100%">
                                  <Title level={4}>{title}</Title>
                              </Block> : title
                          }
                      </Block>
                      }
                      {children}
                      {isClosable &&
                      <CloseIcon
                          className={applyClassName('modal-close')}
                          isCenter
                          onClick={onClose}
                      >
                          <svg
                              width="1em"
                              height="1em"
                              viewBox="0 0 16 17"
                              fill="currentColor"
                              focusable="false"
                          >
                              <path
                                  fill="currentColor"
                                  fillRule="evenodd"
                                  d="M9.426 8.017l4.279 4.278c.393.394.393 1.033 0 1.427-.394.393-1.033.393-1.427 0L8 9.443l-4.278 4.279c-.394.393-1.033.393-1.427 0-.393-.394-.393-1.033 0-1.427l4.279-4.278-4.279-4.278c-.393-.394-.393-1.033 0-1.427.394-.393 1.033-.393 1.427 0L8 6.591l4.278-4.279c.394-.393 1.033-.393 1.427 0 .393.394.393 1.033 0 1.427L9.426 8.017z"
                              />
                          </svg>
                      </CloseIcon>
                      }
                      {footer &&
                      <Block
                        marginTop={2}
                        className={applyClassName('modal-footer')}
                      >
                          {footer}
                      </Block>
                      }
                  </Root>
              </Container>
          </Fragment> : null
  )
}

Modal.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,

  title: PropTypes.any,
  isOpen: PropTypes.bool,
  footer: PropTypes.any,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isClosable: PropTypes.bool,
  isHide: PropTypes.bool,

  onClose: PropTypes.func
}

Modal.defaultProps = {
  isOpen: false,
  footer: null,
  width: '',
  isClosable: true,
  isHide: true
}

export default withTheme(Modal)
