class FactoryProvider {
    ConfigProvider = null
    DeviceProvider = null
    PoslineProvider = null
    TelephonistProvider = null

    init({
      ConfigProvider,
      DeviceProvider,
      PoslineProvider,
      TelephonistProvider = null
    }) {
      this.ConfigProvider = ConfigProvider
      this.DeviceProvider = DeviceProvider
      this.PoslineProvider = PoslineProvider
      this.TelephonistProvider = TelephonistProvider
    }
}

export { FactoryProvider }
export default new FactoryProvider()
