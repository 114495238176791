import _ from 'lodash'
import FactoryModel from 'sdk/models/factoryModel'
import BaseHttpProvider from '../base-http-provider'
import FactoryProvider from '../factoryProvider'

const getRandomString = (length = 32) => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length))
  }
  return result
}

class PoslineProvider extends BaseHttpProvider {
  host = () => `${FactoryProvider.ConfigProvider.config.api.poslineURI}`
  defaultApiPrefix = '/api/v1'

  async sendRequest(lastName, firstName, patronymic, phone, confirmKey) {
    const formData = new FormData()
    formData.append('order[number]', getRandomString(12))
    formData.append('order[redirect_url]', location.href)
    formData.append('order[order_url]', location.href)
    formData.append('order[term]', '6')
    formData.append('order[initial_payment]', '0')
    formData.append('order[is_installment]', FactoryModel.AppModel.isHire)
    formData.append('order[client_firstname]', firstName)
    formData.append('order[client_lastname]', lastName)
    formData.append('order[client_patronymic]', patronymic)
    formData.append('order[client_mobile_phone]', `7${phone}`)
    formData.append('order[phone_confirmation_key]', confirmKey)
    _.each(FactoryModel.AppModel.goods, good => {
      formData.append('order[goods_attributes][][external_id]', '0')
      formData.append('order[goods_attributes][][manufacturer]', good.manufacturer)
      formData.append('order[goods_attributes][][model]', good.name)
      formData.append('order[goods_attributes][][price]', good.price)
      formData.append('order[goods_attributes][][count]', good.count)
      formData.append('order[goods_attributes][][goods_category_name]', good.category)
    })

    const { data } = await this.execute({
      uri: '/partner/orders',
      method: 'POST',
      data: formData,
      headers: {
        Authorization: `Bearer ${FactoryModel.AppModel.token}`,
        'Content-Type': 'multipart/form-data'
      }
    })
    return {
      orderID: _.get(data, 'client_order_id', ''),
      orderURL: _.get(data, 'client_order_url', '')
    }
  }
}

export { PoslineProvider }
