import FactoryModel from 'sdk/models/factoryModel'

const openHelpModal = () => {
  FactoryModel.DialogModel.open(true)
}

const closeHelpModal = () => {
  FactoryModel.DialogModel.open(false)
}

export { openHelpModal, closeHelpModal }
