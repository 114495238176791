import _ from 'lodash'
import UAParser from 'ua-parser-js'
import { isSafe } from 'sdk/utils/object'
import FactoryModel from 'sdk/models/factoryModel'

class DeviceProvider {
  constructor() {
    this.parser = new UAParser()
    this.parser.setUA(window.navigator.userAgent)
    this.info = this.parser.getResult()
  }

  os() {
    return {
      name: this.info.os.name,
      version: this.info.os.version
    }
  }

  location() {
    const configLocation = FactoryModel.AppModel?.orderPayload?.location
    return isSafe(configLocation) ? configLocation : location.href
  }

  device() {
    const device = _.omitBy({
      model: this.info.device.model || null,
      type: this.info.device.type || null,
      vendor: this.info.device.vendor || null
    }, _.isNull)
    return !_.isEmpty(device) ? device : null
  }

  browser() {
    return _.omitBy({
      name: this.info.browser.name || null,
      version: this.info.browser.major || null,
      details: this.info.browser.version || null
    }, _.isNull)
  }
}

export { DeviceProvider }
