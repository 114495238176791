import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import withMargin from 'sdk/ui/common/withMargin'
import { applyClassName } from 'sdk/utils/style'
import icons from './icons'

const Root = styled.div`
  display: inline-block;
  
  & svg {
    max-width: 100%;
    max-height: 100%;
  }
`

const Icon = ({
  className, style,
  icon, size, isRem, color, theme,
  onClick
}) => {
  if (!icons[icon]) return null

  const SVG = icons[icon]
  const resolveColor = color ? theme.colors[color] ? theme.colors[color] : color : 'currentColor'
  return (
        <Root
            className={applyClassName(`icon-${icon}`, className)}
            onClick={onClick}
            style={_.omitBy({
              width: `${isRem ? 14 * size : size}px`,
              color: color ? theme.colors[color] ? theme.colors[color] : color : null,
              cursor: onClick ? 'pointer' : null,
              ...style
            }, _.isNull)}
        >
            <SVG fill={resolveColor} stroke="none"/>
        </Root>
  )
}

Icon.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  icon: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isRem: PropTypes.bool,
  color: PropTypes.string, // theme's color or custom
  onClick: PropTypes.func
}

Icon.defaultProps = {
  size: 1,
  isRem: true,
  color: null
}

export default withTheme(withMargin({ displayName: 'Icon' })(Icon))
