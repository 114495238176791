import _ from 'lodash'
import axios from 'axios'

const axiosCleint = axios.create({
  responseType: 'json',
  maxRedirects: 0
  // withCredentials: true
})

class BaseHttpProvider {
  defaultApiPrefix = ''
  defaultHeaders = {}
  host = () => ''

  async execute({
    uri,
    method,
    data,
    isPrefix = true,
    prefix,
    responseType,
    params,
    headers,
    host = ''
  }) {
    const resolveHost = host || (this.host() === '/' ? '' : this.host())
    const prepareRequest = _.omitBy({
      method: method || 'GET',
      url: `${resolveHost}${isPrefix ? prefix || this.defaultApiPrefix : ''}${uri}`,
      data: data || null,
      responseType: responseType || 'json',
      params: params || null,
      headers: {
        ...this.defaultHeaders,
        ...headers,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Expose-Headers': '*',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Methods': 'GET, HEAD, POST, PUT, PATCH, DELETE, OPTIONS'
      }
    }, value => _.isNull(value))
    return await axiosCleint(prepareRequest)
  }
}

export default BaseHttpProvider
