import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import withMargin from 'sdk/ui/common/withMargin'
import { applyClassName } from 'sdk/utils/style'

const Root = styled(props => {
  const resolveProps = { ...props }
  _.unset(resolveProps, 'isStrong')
  return <span {...resolveProps}/>
})`
  text-align: ${props => props.align};
  font-weight: ${props => (props.isStrong ? 700 : 400)};
  transition: color .3s ease-in-out;
  font-family: ${props => props.theme.fontFamily};
  line-height: 1.5;
  cursor: ${props => (props.onClick ? 'pointer' : 'inherit')};
  
  & * {
    font-family: ${props => props.theme.fontFamily};
    line-height: 1.5;
  }

  & del {
    background: inherit;
  }
  
  & code {
    color: inherit;
  }
  
  & a, a:hover, a:visited, a:focus {
    color: inherit;
    text-decoration: inherit;
    cursor: pointer;
  }
`

const Text = ({
  className, style, onClick,
  type,
  isUnderline, isStrong,
  size, isRem,
  children,
  theme
}) => (
    <Root
        className={applyClassName('text-container', className)}
        onClick={onClick}
        isStrong={isStrong}
        style={{
          color: theme.colors[type] ? theme.colors[type] : type !== 'text' ? type : 'inherit',
          fontSize: size ? _.isNumber(size) ? `${isRem ? size * 14 : size}px` : size : null,
          cursor: onClick ? 'pointer' : 'inherit',
          textDecoration: isUnderline ? 'underline' : 'inherit',
          ...style
        }}
    >
        {children}
    </Root>
)

Text.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,

  onClick: PropTypes.func,

  type: PropTypes.string, // colors of theme or custom color
  isUnderline: PropTypes.bool,
  isStrong: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isRem: PropTypes.bool
}

Text.defaultProps = {
  type: 'text',
  isUnderline: false,
  isStrong: false,
  size: null,
  isRem: true
}

export default withMargin({ displayName: 'Text' })(withTheme(Text))
