import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Paragraph } from 'sdk/ui/Typography'
import PersonalDataAccess from 'sdk/components/PersonalDataAccess'
import Block from 'sdk/ui/Block'

const ConfirmCodeForm = ({
  isSendPhoneCode, phone
}) => {
  if (phone.length < 10) return null

  return (
    <Block marginTop={1}>
      {!isSendPhoneCode ?
        <Paragraph>
          <Paragraph isNativeMargin={false}>Для подтверждения номера мы направим вам СМС с кодом</Paragraph>
        </Paragraph> :
        <Fragment>
          <Paragraph>
            Вводом СМС-кода подтверждения Вы выражаете согласие на обработку персональных данных и
            взаимодействие с бюро кредитных историй в соответствии
            <PersonalDataAccess/>
          </Paragraph>
        </Fragment>
      }
    </Block>
  )
}

ConfirmCodeForm.propTypes = {
  isSendPhoneCode: PropTypes.bool,
  phone: PropTypes.string
}

export default ConfirmCodeForm
