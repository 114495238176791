import { observable, action, computed } from 'mobx'
import { colors, fontSize, fontFamily, fontSizeKit, breakPoints, viewPorts } from 'sdk/styles'
import { darken, hexToRgba, isDark, lighten } from 'sdk/utils/colors'
import BaseModel from '../baseModel'

class ThemeModel extends BaseModel {
  @observable background = colors.background
  @observable text = colors.text

  @computed get isDarkTheme() {
    return isDark(this.background)
  }

  @computed get disable() {
    return hexToRgba(!this.isDarkTheme ? this.text : this.background, 0.65)
  }

  @observable gray1 = colors.gray1
  @observable gray2 = colors.gray2
  @observable gray3 = colors.gray3
  @observable gray4 = colors.gray4
  @observable gray5 = colors.gray5

  @observable overflowBackground = colors.overflowBackground

  @observable primary = colors.primary
  @computed get primaryLight() {
    return lighten(this.primary, 0.1)
  }
  @computed get primaryDark() {
    return darken(this.primary, 0.2)
  }

  @observable success = colors.success
  @observable danger = colors.danger
  @observable warning = colors.warning

  @observable fontSize = fontSize
  @computed get fontSizeKit() {
    return { base: `${this.fontSize}px` }
  }
  @observable fontFamily = fontFamily

  apply = action((theme = {}) => {
    if (theme?.fontSize) {
      this.fontSize = { base: theme?.fontSize }
    }
    if (theme?.fontFamily) this.fontFamily = theme?.fontFamily

    if (theme?.colors?.background) this.background = theme?.colors?.background
    if (theme?.colors?.text) this.text = theme?.colors?.text
    if (theme?.colors?.primary) this.primary = theme?.colors?.primary
    if (theme?.colors?.success) this.success = theme?.colors?.success
    if (theme?.colors?.danger) this.danger = theme?.colors?.danger
    if (theme?.colors?.warning) this.warning = theme?.colors?.warning
    if (theme?.colors?.gray1) this.gray1 = theme?.colors?.gray1
    if (theme?.colors?.gray2) this.gray2 = theme?.colors?.gray2
    if (theme?.colors?.gray3) this.gray3 = theme?.colors?.gray3
    if (theme?.colors?.gray4) this.gray4 = theme?.colors?.gray4
    if (theme?.colors?.gray5) this.gray5 = theme?.colors?.gray5
    if (theme?.colors?.overflowBackground) this.overflowBackground = theme?.colors?.overflowBackground
  })

  @computed get theme() {
    return {
      colors: {
        background: this.background,
        text: this.text,
        isDarkTheme: this.isDarkTheme,
        disable: this.disable,
        primary: this.primary,
        primaryLight: this.primaryLight,
        primaryDark: this.primaryDark,
        success: this.success,
        danger: this.danger,
        warning: this.warning,
        gray1: this.gray1,
        gray2: this.gray2,
        gray3: this.gray3,
        gray4: this.gray4,
        gray5: this.gray5,
        overflowBackground: this.overflowBackground
      },
      breakPoints,
      viewPorts,
      fontSize: this.fontSize,
      fontSizeKit: this.fontSizeKit,
      fontFamily: this.fontFamily
    }
  }
}

export { ThemeModel }
