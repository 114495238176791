import React from 'react'
import { ThemeProvider } from 'styled-components'
import { inject, observer, Provider } from 'mobx-react'
import { mountHook } from 'sdk/hooks/mount'
import Notification from 'sdk/ui/Notification'
import HelpModal from 'sdk/components/HelpModal'
import { factoryModelInit } from 'sdk/models/factoryModel/init'
import FactoryModel from 'sdk/models/factoryModel'

factoryModelInit()

const Root = inject(store => ({
  ResponsiveModel: store?.ResponsiveModel,
  ThemeModel: store?.ThemeModel,
  AppModel: store?.AppModel
}))(observer(({
  ResponsiveModel,
  ThemeModel,
  AppModel
}) => {
  mountHook(() => {
    AppModel.initRender()

    window.addEventListener('resize', () => {
      ResponsiveModel.setWidth(window.innerWidth)
    })
  })

  const resolveTheme = {
    ...ThemeModel.theme,
    width: ResponsiveModel.width,
    isPhone: ResponsiveModel.isPhone,
    isTablet: ResponsiveModel.isTablet,
    isBeforeDesktop: ResponsiveModel.isBeforeDesktop,
    isDesktop: ResponsiveModel.isTabletAndPhone,
    isMiddleDesktop: ResponsiveModel.isMiddleDesktop,
    isLargeDesktop: ResponsiveModel.isLargeDesktop,
    isNotPhone: ResponsiveModel.isNotPhone
  }

  return (
    <ThemeProvider theme={resolveTheme}>
      <Notification/>
      <HelpModal/>
    </ThemeProvider>
  )
}))

const Sdk = () => (
    <Provider {...FactoryModel}>
      <Root/>
    </Provider>
)

export default Sdk
