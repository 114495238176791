import React from 'react'
import FactoryModel from 'sdk/models/factoryModel'

const updateConfig = (cfg = {}) => {
  FactoryModel.AppModel.apply(cfg)
  FactoryModel.DialogModel.apply(cfg)
  FactoryModel.PhoneModel.apply(cfg)
  FactoryModel.ThemeModel.apply(cfg?.theme || {})
}

export default updateConfig
