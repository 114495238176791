import _ from 'lodash'
import React from 'react'
import styled, { keyframes, withTheme } from 'styled-components'
import { inject, observer } from 'mobx-react'
import Icon from 'sdk/ui/Icon'
import Block from 'sdk/ui/Block'
import { hexToRgba, lighten } from 'sdk/utils/colors'

const Root = styled.div`
  position: fixed;
  z-index: 9999999;
  background: transparent;
  top: 20px;
  right: 20px;
`

const MessageIn = keyframes`
  from {
    transform: translateX(120%);
  }
  
  to {
  transform: translateX(0);
  }
`

const MessageContainer = styled.div`
  padding: 20px;
  width: 320px;
  border-radius: 10px;
  font-family: ${props => props.theme.fontFamily};
  line-height: 1.5;
  font-weight: 400;
  background-color: ${props => props.theme.colors.background};
  animation: 0.3s ease-in-out ${MessageIn};
  margin-bottom: 20px;
  box-shadow: 0 0 10px ${props => hexToRgba(props.theme.colors.text, 0.5)};
  user-select: none;
  
  & * {
    user-select: none;
  }
`

const Notification = ({ NotificationModel, theme }) => (
    <Root>
      {_.map(NotificationModel.messages, m => (
          <MessageContainer key={m.id}>
            <Block horizontalPlacement="between" verticalPlacement="center">
              <Block horizontalPlacement="left">
                <span>
                  <Icon icon="warning" color="danger" style={{ marginRight: '16px' }} size={2}/>
                </span>
                <span>{m.message}</span>
              </Block>
              <Block>
                <Icon
                    icon="close"
                    color={theme.colors.text}
                    size={0.7}
                    style={{ transform: 'translateY(-12px)' }}
                    marginLeft={1.42}
                    onClick={() => NotificationModel.remove(m.id)}
                />
              </Block>
            </Block>
          </MessageContainer>
      ))}
    </Root>
)

export default withTheme(inject('NotificationModel')(observer(Notification)))
