import _ from 'lodash'
import { action, runInAction, reaction } from 'mobx'

class BaseModel {
  fields = []

  constructor(fields = []) {
    if (!_.isEmpty(fields)) {
      this.fields = fields
    }
  }

  action = cb => {
    if (!cb) return
    runInAction(async () => {
      await cb()
    })
  }

  setField = (field) => (v) => {
    runInAction(() => {
      const f = _.find(this.fields, fd => fd.field === field)
      const path = _.isObject(f) ? f.path : ''
      if (path) {
        _.set(this, path, v)
      } else {
        this[field] = v
      }
    })
  }

  applyData = action((data) => {
    runInAction(() => {
      _.each(data, (value, path) => {
        _.set(this, path, value)
      })
    })
  })

  applyOnChangeHook(fields, onChangeHook, isAllHook = false) {
    if (!_.isEmpty(fields) && onChangeHook) {
      if (isAllHook) {
        reaction(() => {
          const state = {}
          _.each(fields, field => {
            if (_.isObject(field)) {
              state[field.field] = _.get(this, field.path, null)
            } else {
              state[field] = _.get(this, field)
            }
          })
          return state
        }, state => {
          onChangeHook(state)
        })
      }

      _.each(fields, field => {
        const f = _.find(fields, fd => fd === field || _.get(fd, 'field') === field)

        reaction(() => ({
          field,
          value: !_.get(_.isObject(f) ? _.get(this, f.path, null) : this[f], 'slice', null) ?
            _.isObject(f) ? _.get(this, f.path, null) : this[f] :
            JSON.stringify(_.isObject(f) ? _.get(this, f.path, null) : this[f])
        }), state => {
          onChangeHook(state)
        })
      })
    }
  }
}

export default BaseModel
