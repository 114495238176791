import _ from 'lodash'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Block from 'sdk/ui/Block'
import { applyClassName } from 'sdk/utils/style'

const Root = styled.div`
  color: ${props => props.theme.colors.text};
  font-weight: 400;
  font-family: ${props => props.theme.fontFamily};
  
  & * {
    font-family: ${props => props.theme.fontFamily};
  }
  
`

const ErrorContainer = styled.div`
  color: ${props => props.theme.colors.danger};
  font-weight: 400;
  position: absolute;
  font-size: 11px;
`

const withFormProps = options => OriginalComponent => {
  const displayName = _.get(options, 'displayName', 'ResolveComponent')

  const ResolveComponent = ({
    className, style,

    onFocus, onBlur,

    isFullWidth, isForm, label,
    isShowForceValidate,

    ...props
  }) => {
    const [isInput, setIsInput] = useState(false)
    const handleChangeIsInput = current => {
      setIsInput(current)
    }

    const [isFocus, setIsFocus] = useState(false)
    const handleChangeIsFocus = current => {
      setIsFocus(current)
      if (current && onFocus) {
        onFocus()
      } else if (!current && onBlur) {
        onBlur()
      }
    }

    const isOnlyShowForceValidate = _.get(options, 'isOnlyShowForceValidate', false)
    const isShowValidate = !isOnlyShowForceValidate ? isShowForceValidate : true
    const isShowErrorOrWarn = ((!!isShowValidate && props.error) || (isInput && props.error))
    const error = isShowErrorOrWarn ? props.error : null
    const isExistForm = isForm || !!label || !!error

    const width = _.get(style, 'width', null)
    return (
      isExistForm ?
      <Root
          label={label || null}
          className={applyClassName(`${displayName}-form-container`, className)}
          style={_.omitBy({
            width: isFullWidth && !width ? '100%' : width,
            ...style
          }, _.isNull)}
      >
        {label &&
        <Block marginBottom={0.5} className={applyClassName(`${displayName}-form-label`)}>
          {label}
        </Block>
        }
        <OriginalComponent
          onChangeIsInput={handleChangeIsInput}
          onChangeIsFocus={handleChangeIsFocus}
          isShowForceValidate={isShowForceValidate}
          {...props}
        />
        {error && !isFocus &&
        <ErrorContainer className={applyClassName(`${displayName}-form-error-container`)}>
          {error}
        </ErrorContainer>
        }
      </Root> :
      <OriginalComponent
        className={className}
        style={style}
        {...props}
      />
    )
  }

  ResolveComponent.displayName = `${displayName}Form`

  ResolveComponent.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,

    isForm: PropTypes.bool,
    label: PropTypes.any,
    isFullWidth: PropTypes.bool,

    error: PropTypes.string,
    isShowForceValidate: PropTypes.bool
  }

  ResolveComponent.defaultProps = {
    isForm: false,
    label: null,
    isFullWidth: true,

    error: null,
    isShowForceValidate: false
  }

  return ResolveComponent
}

export default withFormProps
