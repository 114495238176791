import tinyColor from 'tinycolor2'

export function hexToRgba(color, amount = 1) {
  return tinyColor(color).setAlpha(amount).toRgbString()
}

export function darken(color, amount) {
  return tinyColor(color).darken(amount * 100).toHexString()
}

export function lighten(color, amount) {
  return tinyColor(color).lighten(amount * 100).toHexString()
}

export function isDark(color) {
  return tinyColor(color).isDark()
}
