import { ConfigProvider } from '../config-provider'
import { DeviceProvider } from '../device-provider'
import { PoslineProvider } from '../api-provider/posline-provider'
import { TelephonistProvider } from '../api-provider/telephonist-provider'
import FactoryProvider from './factoryProvider'

export const factoryProviderInit = () => {
  const providers = {
    ConfigProvider: new ConfigProvider(),
    DeviceProvider: new DeviceProvider(),
    PoslineProvider: new PoslineProvider(),
    TelephonistProvider: new TelephonistProvider()
  }

  FactoryProvider.init(providers)
}
