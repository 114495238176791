import React from 'react'
import FactoryModel from 'sdk/models/factoryModel'
import { render } from 'react-dom'
import Sdk from 'sdk/Sdk'
import updateConfig from '../updateConfig'

const init = (cfg = {}) => {
  updateConfig(cfg)

  if (!FactoryModel.AppModel.isRender) {
    const rootDom = document.createElement('div')
    rootDom.style.position = 'fixed'
    rootDom.style.left = '-9999999px'
    rootDom.style.top = '-9999999px'
    rootDom.style.zIndex = '1000000'
    document.body.appendChild(rootDom)
    render(<Sdk/>, rootDom)
  }
}

export default init
