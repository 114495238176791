import { useEffect } from 'react'

export function mountHook(mount, unmount) {
  useEffect(() => {
    const asyncCB = async () => {
      if (mount) await mount()
    }
    asyncCB()
    return () => {
      if (unmount) unmount()
    }
  }, [])
}
