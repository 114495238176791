import { observable, action, computed } from 'mobx'
import FactoryProvider from 'sdk/providers/factoryProvider'
import FactoryModel from 'sdk/models/factoryModel'
import BaseModel from '../baseModel'

class PhoneModel extends BaseModel {
  @observable phone = ''
  @observable confirmKey = ''
  @observable confirmCode = ''
  @observable isConfirmCode = false
  @observable isSendPhoneCode = false
  @observable isLoadingConfirmCode = false
  @observable isSendConfirmKey = false

  @computed get isValidPhone() {
    return this.phone.length >= 10
  }

  @computed get isValidConfirmCode() {
    return this.confirmCode.length >= 5
  }

  @computed get isValidConfirmProcess() {
    return this.isValidConfirmCode && this.isValidPhone
  }

  clear = action(() => {
    this.phone = ''
    this.confirmKey = ''
    this.confirmCode = ''
    this.isConfirmCode = false
    this.isSendPhoneCode = false
    this.isLoadingConfirmCode = false
    this.isSendConfirmKey = false
  })

  apply = action(data => {
    let resolvePhone = data?.phone
    if (resolvePhone) {
      resolvePhone = resolvePhone.replace(/\+/ig, '')
      resolvePhone = resolvePhone.length > 10 ? resolvePhone.substring(1) : resolvePhone
      this.phone = resolvePhone
    }

    if (data?.confirmKey) {
      this.confirmKey = data?.confirmKey
      this.isConfirmCode = true
      this.isSendPhoneCode = true
    }
  })

  changePhone = action(phone => {
    if (this.phone !== phone) {
      this.confirmKey = ''
      this.confirmCode = ''
      this.isConfirmCode = false
      this.isSendPhoneCode = false
      this.isLoadingConfirmCode = false
      this.isSendConfirmKey = false
    }
    this.phone = phone
  })

  sendConfirmCode = async () => {
    try {
      this.applyData({ isSendConfirmKey: true })
      const key = await FactoryProvider.TelephonistProvider.getConfirmCode(this.phone)
      this.applyData({
        confirmKey: key,
        isSendPhoneCode: true
      })
    } catch (e) {
      FactoryModel.NotificationModel.notifyError({ message: e, forceMessage: 'Не удается отправить код подтверждения.' })
    } finally {
      this.applyData({ isSendConfirmKey: false })
    }
  }

  confirm = async () => {
    try {
      this.applyData({ isLoadingConfirmCode: true })
      const { isSuccess, isForPoslineApi } = await FactoryProvider.TelephonistProvider.confirmCode(this.confirmCode, this.confirmKey)
      if (isSuccess) {
        FactoryModel.DialogModel.setField('isSendToPosline')(isForPoslineApi)
        this.applyData({
          isConfirmCode: true,
          confirmCode: ''
        })
      }
      if (FactoryModel.DialogModel.isFillName) {
        FactoryModel.DialogModel.send()
      }
    } catch (e) {
      FactoryModel.NotificationModel.notifyError({ message: e, forceMessage: 'Не удается подтвердить код.' })
    } finally {
      this.applyData({ isLoadingConfirmCode: false })
    }
  }
}

export { PhoneModel }
