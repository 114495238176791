import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { applyClassName } from 'sdk/utils/style'

const Root = styled(props => {
  const resolveProps = { ...props }
  _.unset(resolveProps, 'isError')
  _.unset(resolveProps, 'isFocus')
  _.unset(resolveProps, 'textAlign')
  return <div {...resolveProps}/>
})`
  text-overflow: ellipsis;
  touch-action: manipulation;
  -webkit-appearance: none;
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: ${props => props.theme.colors.text};
  line-height: 1.5;
  background-color: ${props => props.theme.colors.background};
  background-image: none;
  border: 2px solid ${props => (props.isFocus ? props.theme.colors.primary : props.isError ? props.theme.colors.danger : props.theme.colors.gray1)};
  border-radius: 8px;
  transition: all 0.3s;
  font-family: ${props => props.theme.fontFamily};
  box-shadow: ${props => (props.isFocus ? `0 0 1px 2px ${props.theme.colors.primaryLight}` : 'none')};
  
  & * {
    font-family: ${props => props.theme.fontFamily};
  }
  
  &:hover {
    border-color: ${props => props.theme.colors.primary};
  }
`

const RootInput = styled(props => {
  const resolveProps = { ...props }
  _.unset(resolveProps, 'textAlign')
  return <input {...resolveProps}/>
})`
  text-overflow: ellipsis;
  touch-action: manipulation;
  -webkit-appearance: none;
  height: auto;
  padding: 0;
  font-size: 16px;
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
  width: 100%;
  color: ${props => props.theme.colors.text};
  line-height: 1.5;
  background-color: ${props => props.theme.colors.background};
  background-image: none;
  border: none;
  border-radius: 0;
  transition: none;
  flex: 1;
  font-family: ${props => props.theme.fontFamily};
  outline: none;
  text-align: ${props => props.textAlign};
  
  & * {
    font-family: ${props => props.theme.fontFamily};
  }
  
  &:hover {
    border-color: transparent;
  }
  
  &:focus {
    border-color: transparent;
    outline: 0;
  }
  
  &::placeholder {
    color: ${props => (!props.isDarkTheme ? props.theme.colors.gray2 : props.theme.colors.gray3)};
  }
`

const PrefixContainer = styled.div`
  margin-right: 7px;
`

const PostfixContainer = styled.div`
  margin-left: 7px;
`

const InputComponent = ({
  className, style,
  value, isDisable, placeholder,
  tabIndex, isFocus, prefix, postfix, error, isInput,
  onFocus, onBlur, onChange, onKeyDown, onKeyUp, onKeyPress, onClick, textAlign
}) => (
    <Root
        className={className}
        isError={isInput && !!error && !isFocus}
        isFocus={isFocus}
        style={style}
    >
        {prefix &&
        <PrefixContainer className={applyClassName('input-prefix-container')}>
          {prefix}
        </PrefixContainer>
        }
        <RootInput
            className={applyClassName('input')}
            style={{ width: '100%' }}
            disabled={isDisable}
            placeholder={placeholder}
            value={value}
            tabIndex={tabIndex}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            onKeyPress={onKeyPress}
            onClick={onClick}
            textAlign={textAlign}
        />
        {postfix &&
        <PostfixContainer className={applyClassName('input-postfix')}>
          {postfix}
        </PostfixContainer>
        }
    </Root>
)

export default InputComponent
