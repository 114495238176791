import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import Modal from 'sdk/ui/Modal'
import { Paragraph, Text } from 'sdk/ui/Typography'
import Block from 'sdk/ui/Block'
import Button from 'sdk/ui/Button'
import styled, { withTheme } from 'styled-components'

const RootMarkdown = styled(Block)`
  text-align: justify;
`

const ParagraphContainer = styled(Paragraph)`
  text-align: justify;
`

const PersonalDataAccess = ({ label, theme }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Fragment>
      <Text
        style={{ cursor: 'pointer' }}
        onClick={() => setIsOpen(true)}
        isUnderline
      >
        <Text type={theme.colors.primary}>{label}</Text>
      </Text>
      <Modal
        isOpen={isOpen}
        title="Согласие на обработку персональных данных"
        onClose={() => setIsOpen(false)}
        width="700px"
        isCenter
        footer={
          <Block isCenter>
            <Block>
              <Button
                type="primary"
                onClick={() => setIsOpen(false)}
                marginBottom={1}
              >
                Закрыть
              </Button>
            </Block>
          </Block>
        }
      >
        <RootMarkdown>
          <ParagraphContainer marginBottom={1}>
            Обществу с ограниченной ответственностью "СМАРТФИН", адрес: 620026, г. Екатеринбург, Белинского д. 86, офис 402 (далее – «Оператор»), являясь администратором сайта в информационно-телекоммуникационной сети «Интернет»: <a href="https://s-finance.pro">https://s-finance.pro</a> (далее – «Сайт»), организует обработку персональных данных Пользователя, предоставленных таким Пользователем на Сайте при заполнении анкеты.
          </ParagraphContainer>
          <ParagraphContainer marginBottom={1}>
            СМС-код, направленный Оператором Пользователю на номер мобильного телефона, указанный в анкете на Сайте, используется в соответствии с Федеральным законом от 06.04.2011 г. № 63-ФЗ «Об электронной подписи» в качестве простой электронной подписи Пользователя, для формирования им каждого электронного документа. В случае идентичности СМС-кода, направленного Оператором, и СМС-кода, введенного Пользователем в специальном разделе анкеты на Сайте для подтверждения номера мобильного телефона, указанного Пользователем в анкете на Сайте, такая электронная подпись считается подлинной и предоставленной Пользователем, а документ, подписанный такой электронной подписью рассматривается как электронный документ, подписанный простой электронной подписью Пользователя.
          </ParagraphContainer>
          <ParagraphContainer marginBottom={1}>
            Совершая конклюдентные действия по вводу СМС-кода подтверждения, направленного Оператором Пользователю на номер мобильного телефона, указанный в анкете на Сайте, Пользователь, в качестве субъекта персональных данных, свободно, своей волей и в своем интересе выражает согласие в соответствии с Федеральным законом от 27.07.2006 г. № 152-ФЗ «О персональных данных» Оператору и Партнеру Оператора на обработку персональных данных, указанных Пользователем в анкете на Сайте, путем совершения с использованием средств автоматизации сбора, записи, систематизации, накопления, хранения, уточнения (обновления, изменения), извлечения, использования, передачи (распространения, предоставления, доступа), обезличивания, блокирования, удаления, уничтожения таких персональных данных.
          </ParagraphContainer>
          <ParagraphContainer marginBottom={1}>
            Согласие на обработку персональных данных предоставляется Пользователем в целях рассмотрения заявления Пользователя о предоставлении потребительского кредита (займа), а также дополнительных услуг, оказываемых Оператором и/или Партнером Оператора, заключения по инициативе Пользователя договора потребительского кредита (займа), заключения и исполнения договора страхования, договора об оказании иных дополнительных услуг.
          </ParagraphContainer>
          <ParagraphContainer marginBottom={1}>
            Пользователь подтверждает достоверность сведений, указанных таким Пользователем в анкете на Сайте.
          </ParagraphContainer>
          <ParagraphContainer marginBottom={1}>
            Также Пользователь выражает, в качестве субъекта кредитной истории, согласие Партнеру Оператора, как пользователю кредитной истории, на предоставление информации и получение кредитного отчета по Пользователю в любом бюро кредитных историй и Центральном каталоге кредитных историй, в объеме и порядке, предусмотренными Федеральным законом от 30.12.2004 г. № 218-ФЗ «О кредитных историях».
          </ParagraphContainer>
          <ParagraphContainer marginBottom={1}>
            Пользователь в соответствии с Федеральным законом от 07.07.2003 г. № 126-ФЗ «О связи» выражает согласие Оператору, Партнеру Оператора осуществлять взаимодействие с Пользователем посредством его информирования по телефону, рассылкой по сети подвижной радиотелефонной связи коротких текстовых сообщений, в том числе рекламного характера, на номер мобильного телефона, указанный Пользователем в анкете на Сайте.
          </ParagraphContainer>
          <ParagraphContainer marginBottom={1}>
            Согласие на обработку персональных данных предоставляется Пользователем на срок 5 (пять) лет, но не дольше, чем этого требуют цели обработки и может быть отозвано Пользователем посредством направления письменного уведомления об отзыве согласия на почтовый адрес Оператора.
          </ParagraphContainer>
          <ParagraphContainer marginBottom={1}>
            Порядок обработки и защиты персональных данных Пользователей, предоставленных Пользователями на сайте в информационно-телекоммуникационной сети «Интернет»: <a href="https://s-finance.pro">https://s-finance.pro</a> регламентируется Политикой Оператора в отношении персональных данных, обрабатываемых в рамках деятельности по предоставлению финансового посредничества.
          </ParagraphContainer>
          <ParagraphContainer marginBottom={1}>
            <b>Перечень Партнеров Оператора, организующих и осуществляющих обработку ПДН Субъекта совместно с Оператором или по поручению Оператора:</b>
          </ParagraphContainer>
          <ParagraphContainer marginBottom={1}>
            КБ «Ренессанс Кредит» (ООО) (адрес: 115114, г. Москва, Кожевническая улица, д. 14);
          </ParagraphContainer>
          <ParagraphContainer marginBottom={1}>
            АО «Банк Русский Стандарт» (адрес: 105187, г. Москва, ул. Ткацкая, д. 36);
          </ParagraphContainer>
          <ParagraphContainer marginBottom={1}>
            АО «Тинькофф Банк» (адрес: 123060, г. Москва, 1-й Волоколамский проезд, д. 10, стр. 1);
          </ParagraphContainer>
          <ParagraphContainer marginBottom={1}>
            ООО МФК «Т-Финанс» (адрес: 123060, г. Москва, 1-й Волоколамский проезд, д. 10, стр. 1);
          </ParagraphContainer>
          <ParagraphContainer marginBottom={1}>
            АО «Почта Банк» (адрес: 107061, г. Москва, Преображенская площадь, д. 8);
          </ParagraphContainer>
          <ParagraphContainer marginBottom={1}>
            АО «Кредит Европа Банк (Россия)» (адрес: 129090, г. Москва, Олимпийский проспект, д. 14);
          </ParagraphContainer>
          <ParagraphContainer marginBottom={1}>
            АО «ОТП Банк» (адрес: 125171, г. Москва, Ленинградское шоссе, д.16А, стр. 2);
          </ParagraphContainer>
          <ParagraphContainer marginBottom={1}>
            ООО МФК «ОТП Финанс» (адрес: 127299, г. Москва, ул. Клары Цеткин, д. 4А);
          </ParagraphContainer>
          <ParagraphContainer marginBottom={1}>
            ПАО «МТС-Банк» (адрес: 115432, г. Москва, просп. Андропова, д. 18, корп.1);
          </ParagraphContainer>
          <ParagraphContainer marginBottom={1}>
            ООО МФК «МигКредит» (адрес: 127018, г. Москва, ул. Сущевский Вал, д. 5, стр. 3);
          </ParagraphContainer>
          <ParagraphContainer marginBottom={1}>
            АО «АЛЬФА-БАНК» (адрес: 107078, г. Москва, ул. Каланчевская, д. 27);
          </ParagraphContainer>
          <ParagraphContainer marginBottom={1}>
            ООО «Капитал Лайф Страхование Жизни» (адрес: 115035, г. Москва, Кадашёвская набережная, д. 30);
          </ParagraphContainer>
          <ParagraphContainer marginBottom={1}>
            ООО «СК «Ренессанс Жизнь» (адрес: 115114, г. Москва, Дербеневская наб., д.7, стр. 22, этаж 4, пом.13, ком.11);
          </ParagraphContainer>
          <ParagraphContainer marginBottom={1}>
            ООО «Сравни.ру» (адрес: 109544, г. Москва, Энтузиастов б-р, д. 2, эт.26);
          </ParagraphContainer>
          <ParagraphContainer marginBottom={1}>
            ПАО "Совкомбанк" (156000, Кострома, пр-т Текстильщиков, д. 46);
          </ParagraphContainer>
          <ParagraphContainer marginBottom={1}>
            ООО «Денум тех» (121552, г. Москва, Муниципальный округ Крылатское вн. тер.г., ул. Островная, дом 2, офис 316);
          </ParagraphContainer>
          <ParagraphContainer marginBottom={1}>
            ООО МКК «Быстрые покупки» (156000, Костромская область, г.о. город Кострома, г. Кострома, пр-кт Текстильщиков, д. 46, помещ./этаж 1/3, ком. 56);
          </ParagraphContainer>
          <ParagraphContainer marginBottom={1}>
            КБ «ЛОКО-Банк» (АО) (125167, г. Москва, Ленинградский проспект, дом 39, строение 80).
          </ParagraphContainer>
        </RootMarkdown>
      </Modal>
    </Fragment>
  )
}

PersonalDataAccess.propTypes = {
  label: PropTypes.string
}

PersonalDataAccess.defaultProps = {
  label: ' с условиями обработки персональных данных'
}

export default withTheme(PersonalDataAccess)
