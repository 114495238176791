import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import ReactInputMask from 'react-input-mask'

const MaskInput = props => (
    <ReactInputMask {...props}>
        {inputProps => (
            <props.Input
                {..._.merge({}, props, inputProps)}
            />
        )}
    </ReactInputMask>
)

MaskInput.propTypes = {
  mask: PropTypes.string,
  placeholder: PropTypes.string,
  Input: PropTypes.any
}

export default MaskInput
