import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import { safeObject, isSafe } from 'sdk/utils/object'
import { r2px } from 'sdk/utils/style'

const withMargin = options => OriginalComponent => {
  const displayName = _.get(options, 'displayName', 'ResolveComponent')
  const forceIsRem = _.get(options, 'forceIsRem', true)

  const ResolveComponent = ({
    style, width, height, isRem,
    ...props
  }) => {
    const isWidth = _.get(options, 'isWidth', true)
    const isHeight = _.get(options, 'isHeight', true)
    const remPoint = 14
    const point = isRem ? remPoint : 1

    const marginTop = isSafe(props.marginTop) ? r2px(props.marginTop, point) : null
    const marginBottom = isSafe(props.marginBottom) ? r2px(props.marginBottom, point) : null
    const marginLeft = isSafe(props.marginLeft) ? r2px(props.marginLeft, point) : null
    const marginRight = isSafe(props.marginRight) ? r2px(props.marginRight, point) : null

    return (
        <OriginalComponent
            style={{
              ...style,
              ...safeObject({
                marginTop,
                marginBottom,
                marginLeft,
                marginRight,
                width: width && isWidth ? _.isNumber(width) ? `${width * point}px` : width : null,
                height: height && isHeight ? _.isNumber(height) ? `${height * point}px` : height : null
              })
            }}
            {...safeObject({
              width: !isWidth ? width : null,
              height: !isHeight ? height : null,
              isRem: forceIsRem ? isRem : null
            })}
            {...props}
        />
    )
  }

  ResolveComponent.displayName = `${displayName}Margin`

  ResolveComponent.propTypes = {
    style: PropTypes.object,
    isRem: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    marginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    marginBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    marginLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    marginRight: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }

  ResolveComponent.defaultProps = {
    width: null,
    height: null,
    isRem: true
  }

  return ResolveComponent
}

export default withMargin
