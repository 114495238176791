import { configure } from 'mobx'

configure({ enforceActions: 'observed' })

class FactoryModel {
    ResponsiveModel = null
    ThemeModel = null
    NotificationModel = null
    AppModel = null
    DialogModel = null
    PhoneModel = null

    init({
      ResponsiveModel,
      ThemeModel,
      NotificationModel,
      AppModel,
      DialogModel,
      PhoneModel
    }) {
      this.ResponsiveModel = ResponsiveModel
      this.ThemeModel = ThemeModel
      this.NotificationModel = NotificationModel
      this.AppModel = AppModel
      this.DialogModel = DialogModel
      this.PhoneModel = PhoneModel
    }
}

export { FactoryModel }
export default new FactoryModel()
