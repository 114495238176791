import init from './init'
import updateConfig from './updateConfig'
import { closeHelpModal, openHelpModal } from './openHelpModal'

export default {
  init,
  updateConfig,
  openHelpModal,
  closeHelpModal
}
